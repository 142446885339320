import { useContext, createContext, useState } from "react";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [accessToken, setAccessToken] = useState(localStorage.getItem("accessToken") || "");
  const [refreshToken, setRefreshToken] = useState(localStorage.getItem("refreshToken") || "");
  const navigate = useNavigate();
  const loginAction = async (data) => {
    try {
      const response = await fetch("https://api.xalq-tashabbusi.uz/api/v1/auth/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      const res = await response.json();
      // console.log(res)
      if (res.success) {
        //setUser(res.data.user);
        setAccessToken(res.data.accessToken);
        setRefreshToken(res.data.refreshToken);
        localStorage.setItem("tokenType", res.data.type);
        localStorage.setItem("accessToken", res.data.accessToken);
        localStorage.setItem("refreshToken", res.data.refreshToken);

        /* console.log(`${localStorage.getItem('tokenType')} ${localStorage.getItem('accessToken')}`); */

        getProfile(true)
        navigate("/app");
        return;
      }

      throw new Error(res.message);
    } catch (err) {
      console.error(err);
    }
  };

  const getProfile = async (isSaveProfile) => {
    await fetch("https://api.xalq-tashabbusi.uz/api/v1/profile/me", {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${localStorage.getItem('tokenType')} ${localStorage.getItem('accessToken')}`,
      },
    })
      .then((r) => {
        if (!r.ok) {
          throw new Error('Network response was not ok');
        }
        return r.json(); // Parse response as JSON
      })
      .then((r) => {
        if (isSaveProfile) setUser(r.data);
        console.log(r.data);
      })
      .catch((error) => {
        console.error('There was a problem with the fetch operation:', error);
        // Optionally handle errors here (e.g., show a message to the user)
      });
  }


  const logOut = () => {
    setUser(null);
    setAccessToken("");
    setRefreshToken("")
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    navigate("/auth/login");
  };

  return (
    <AuthContext.Provider value={{ accessToken, refreshToken, user, loginAction, getProfile ,logOut }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

export const useAuth = () => {
  return useContext(AuthContext);
};