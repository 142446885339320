import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../contexts/AuthProvider";

const PrivateRoute = ({children}) => {
  const {user} = useAuth();
  console.log(user)
  if (!user) return <Navigate to="auth/login" />;
  return children;
};

export default PrivateRoute;