import AuthProvider from "./contexts/AuthProvider"
// routes
import Router from "./routes";
// theme
import ThemeProvider from './theme';
// components
import ThemeSettings from './components/settings';

function App() {
  return (
    <AuthProvider>
      <ThemeProvider>
      <ThemeSettings>
        {" "}
        <Router />{" "}
      </ThemeSettings>
    </ThemeProvider>
    </AuthProvider>
  );
}

export default App;
